import { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "../spinner/Spinner";
import ErrorMessage from "../errorMessage/ErrorMessage";
import MarvelService from "../../services/MarvelService";

import './charList.scss';
import CharInfo from "../charInfo/CharInfo";

class CharList extends Component {
   state = {
      charList: [],
      loading: true,
      error: false,
      newItemLoading: false,
      offset: 210,
      charEnded: false,
      selectedId: null
   }

   marvelService = new MarvelService();

   componentDidMount() {
      this.onRequest();
   }

   onRequest = (offset) => {
      this.onCharListLoading();
      this.marvelService.getAllCharacters(offset)
         .then(this.onCharListLoaded)
         .catch(this.onError);
   }

   onCharListLoading = () => {
      this.setState({ newItemLoading: true });
   }

   onCharListLoaded = (charList) => {
      this.setState({
         charList: [...this.state.charList, ...charList],
         loading: false,
         newItemLoading: false,
         offset: this.state.offset + 9,
         charEnded: charList.length < 8
      });
   }

   onError = () => {
      this.setState({loading: false, error: true});
   }

   clickItem = (id) => {
      this.props.getCharId(id);
      this.setState({ selectedId: id });
   }

   items = (charList) => {
      const { selectedId } = this.state;

      const items = charList.map(item => {
         const hasImg = item.thumbnail.indexOf('image_not_available') === -1;

         return (
            <li
               className={`char__item ${selectedId === item.id ? 'char__item_selected' : ''}`}
               key={item.id}
               onClick={() => this.clickItem(item.id)}
            >
               <img
                  className={`${hasImg ? '' : 'contain'}`}
                  src={item.thumbnail}
                  alt={item.name}
               />
               <div className="char__name">{item.name}</div>
            </li>
         );
      });

      // А эта конструкция вынесена для центровки спиннера/ошибки
      return (
         <ul className="char__grid">
            { items }
         </ul>
      );
   };

   render() {
      const {charList, loading, error, offset, newItemLoading, charEnded} = this.state;
      const items = this.items(charList);
      const errorMessage = error ? <ErrorMessage /> : null;
      const spinner = loading ? <Spinner /> : null;
      const content = !(loading || error) ? items : null;

      return (
         <div className="char__list">
            { errorMessage }
            { spinner }

            <ul className="char__grid">
               { content }
            </ul>

            <button
               className={`button button__main button__long ${charEnded ? 'hide' : ''}`}
               disabled={newItemLoading}
               onClick={() => this.onRequest(offset)}
            >
               <div className="inner">load more</div>
            </button>
         </div>
      );
   };
}

CharInfo.propTypes = {
   getCharId: PropTypes.func
}

export default CharList;